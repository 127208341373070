
header.header {
    line-height: 1;
    border-bottom: 1px solid #e5e5e5;
    padding: 1rem;
    .offcanvas{
        .offcanvas-body{
            >div{
                display: flex;
                justify-content: flex-end;
                >a{
                    
                    margin: 0 3px;
                }
            }
            >a.link-secondary{
                display: block;
                width:100%;
                margin-bottom: 20px;
            }
        }
    }
    >.row.header_wrapper{
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        >div{ // 3 columns (left-logo-right)
            &.header-links-left{
                a.link-secondary{
                    display: block;    
                }
                a.menuIcon{
                    display: none;
                }
            }
            &.logo-left{
                >a.header-site-logo{
                    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
                    font-size: 2.25rem;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            &.header-links-right{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                >a.btn{
                    margin: 3px;
                }
                >a.user-link{
                    display: block;
                    margin:0 5px
                }
                
            }
        }
    }
    .language-dropdown {
        .dropdown-menu {
            min-width:inherit;
            .dropdown-item {
                height: 40px;
                vertical-align: middle;
                justify-content: center;
                align-items: center;
                display: flex;
            }
        }
    }
    @media screen and (max-width: 769px) {
        .offcanvas{
            .offcanvas-body{
                .header-links-right{
                    width: 100%;
                }
            }
        }
        >.row.header_wrapper{
            // display: block;
            >div{ // 3 columns (left-logo-right)
                &.header-links-left{
                    width: auto;
                    a.link-secondary{
                        display: none;    
                    }
                    a.menuIcon{
                        display: block;
                        >svg.menu_icon{
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
                &.logo-left{
                    width: auto;
                    padding: 0;
                    margin-right: auto;
                    margin-left: 5px;
                    >a.header-site-logo{
                        font-size: 1.75rem;
                    }
                }
                &.header-links-right{
                    display: none;
                }
            }
        }
    }
}
// topics
>.nav-scroller{
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
    padding : 0.25rem 1rem;
    >nav.nav{
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        text-align: center;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        >a{
            padding-top: .75rem;
            padding-bottom: .75rem;
            font-size: .875rem;
            &.text-decoration-none{
                cursor: default;
            }
        }
    }
    
}