$body-gray: #f9f9f9 !default;
$white-bg: #FFFFFF!default;
@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');
$gr-1:linear-gradient(170deg, #01E4F8 0%, #1D3EDE 100%);
$gr-2:linear-gradient(170deg, #B4EC51 0%, #429321 100%);
$gr-3:linear-gradient(170deg, #C86DD7 0%, #3023AE 100%);

// .App{mix-blend-mode: overlay;}
body {
    margin: 0;
    padding:0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    #root{
        code {
            font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
        }
        h1, h2, h3, h4, h5, h6 {
            font-family: "Playfair Display", Georgia, serif;
        }
        h2 {
            margin-top: 2rem;
        }

        button.btn-transparent{
            background: none;
            border: none;
        }

        @import "./header.scss";
        header.header {
            // background-color: $white-bg;
        }
        >.nav-scroller{
            // background-color: $white-bg;
        }
        #page_wrapper{
            // background-color: $white-bg;
            #page_content{
                main.container{


                    /* Loading display */
                    .loading_prepage{
                        .loading_wrapper{
                            .loading{
                                text-align: center;
                            }
                        }
                    }
                    .homepage_container{
                        .hp-first-row{

                        }
                        .hp-second-row{
                            
                            .coolcards{
                                @import "./coolcard.scss";
                            }
                            .editorialcards{
                                @import "./editorialcard.scss";
                            }
                        }
                        
                    }
                    /* Content display */
                    @import "./auth.scss";
                    @import "./news.scss";

                }
            }
        }
        @import "./footer.scss";
        footer.footer {
            background-color: $body-gray;
        }
        
    }

    

}


.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}

.card-img-right {
    height: 100%;
    border-radius: 0 3px 3px 0;
}
  
.flex-auto {
    // flex: 0 0 auto;
}
  
.h-250 { height: 250px; }
@media (min-width: 768px) {
    .h-md-250 { height: 250px; }
}
  
  /* Pagination */
.blog-pagination {
    margin-bottom: 4rem;
}
.blog-pagination > .btn {
    border-radius: 2rem;
}
  
/*
* Blog posts
*/
.blog-post {
    margin-bottom: 4rem;
}
.blog-post-title {
    margin-bottom: .25rem;
    font-size: 2.5rem;
}
.blog-post-meta {
    margin-bottom: 1.25rem;
    color: #727272;
}
  

  