// @import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap");
$main-green: #79dd09 !default;
$main-green-rgb-015: rgba(121, 221, 9, 0.1) !default;
$main-yellow: #bdbb49 !default;
$main-yellow-rgb-015: rgba(189, 187, 73, 0.1) !default;
$main-red: #bd150b !default;
$main-red-rgb-015: rgba(189, 21, 11, 0.1) !default;
$main-blue: #0076bd !default;
$main-blue-rgb-015: rgba(0, 118, 189, 0.1) !default;

// general
article{
    span.art_id{
        font-size:10px;
    }
    &.second-mid-article{
        span.art_id{
            text-align: right;
            display: block;
            margin-top: -20px;
        }
    }
}
// specific
article.first-top-article{
    >.row{

        .image_wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            img{
                flex-shrink: 0;
                min-width: 100%;
                min-height: 100%;
                max-width: 100%;
                max-height: 100%
            }
        }
        >div{
            >h1.display-4 {
                font-size: 3rem;
            }
            >div,
            >p{
                font-size: 16px;
            }
        }
    }
    
    @media (max-width: 1200px) {
        >.row{
            >div{
                >h1.display-4 {
                    font-size: 2.5rem;
                }
                >div,>p{
                    font-size: 15px;
                }
            }
        }
    }
    @media (max-width: 992px) {
        >.row{
            >div{
                >h1.display-4 {
                    font-size: 2rem;
                }
                >div,>p{
                    font-size: 14px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        >.row{
            flex-direction: column-reverse;
            >div{
                >h1.display-4 {
                    font-size: 1.5rem;
                }
                >div,>p{
                    font-size: 12px;
                }
            }
            .image_wrapper{
                margin-bottom: 15px;
            }
        }
    }
}
div{
    >article.second-mid-article{
        .desc-article{
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
                    line-clamp: 2; 
            -webkit-box-orient: vertical;
        }
    }
    >article.third-bot-article.postcard{
        flex-wrap: wrap;
        display: flex;
        box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
        border-radius: 10px;
        margin: 0 0 2rem 0;
        overflow: hidden;
        position: relative;
        color: #ffffff;
        h1,	.h1 {
            margin-bottom: 0.5rem;
            font-weight: 500;
            line-height: 1.2;
        }
        .postcard__img {
            max-height: 180px;
            width: 100%;
            object-fit: cover;
            position: relative;
        }
        .postcard__text{
            padding: 1.5rem;
            position: relative;
            display: flex;
            flex-direction: column;
            .postcard__title {
                font-size: 1.75rem;
            }
            .postcard__bar {
                width: 50px;
                height: 10px;
                margin: 10px 0;
                border-radius: 5px;
                background-color: #424242;
                transition: width 0.2s ease;
            }
            .postcard__preview-txt {
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: justify;
                height: 100%;
            }
            .postcard__tagbox {
                display: flex;
                flex-flow: row wrap;
                font-size: 14px;
                margin: 20px 0 0 0;
                padding: 0;
                justify-content: center;
                .tag__item {
                    display: inline-block;
                    background: rgba(83, 83, 83, 0.4);
                    border-radius: 3px;
                    padding: 2.5px 10px;
                    margin: 0 5px 5px 0;
                    cursor: default;
                    user-select: none;
                    transition: background-color 0.3s;
                    &:hover {
                        background: rgba(83, 83, 83, 0.8);
                    }
                }
            }
        }
        &.dark {
            background-color: #18151f;
        }
        &.light {
            background-color: #e1e5ea;
        }
        .t-dark {
            color: #18151f;
        }
        a {
            color: inherit;
        }
        .small {
            font-size: 80%;
        }
        .postcard__img_link {
            display: contents;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(-70deg, #424242, transparent 50%);
            opacity: 1;
            border-radius: 10px;
        }

        &:hover .postcard__bar {
            width: 100px;
        }
    
        /* COLORS */
        .postcard__tagbox .green.play:hover {
            background: $main-green;
            color: black;
        }
        &.green .postcard__title:hover {
            color: $main-green;
        }
        &.green .postcard__bar {
            background-color: $main-green;
        }
        &.green::before {
            background-image: linear-gradient(
                -30deg,
                $main-green-rgb-015,
                transparent 50%
            );
        }
        &.green:nth-child(2n)::before {
            background-image: linear-gradient(30deg, $main-green-rgb-015, transparent 50%);
        }
        &.postcard .postcard__tagbox .blue.play:hover {
            background: $main-blue;
        }
        &.blue .postcard__title:hover {
            color: $main-blue;
        }
        &.blue .postcard__bar {
            background-color: $main-blue;
        }
        &.blue::before {
            background-image: linear-gradient(-30deg, $main-blue-rgb-015, transparent 50%);
        }
        &.blue:nth-child(2n)::before {
            background-image: linear-gradient(30deg, $main-blue-rgb-015, transparent 50%);
        }
        .postcard__tagbox .red.play:hover {
            background: $main-red;
        } 
        &.red .postcard__title:hover {
            color: $main-red;
        }
        &.red .postcard__bar {
            background-color: $main-red;
        }
        &.red::before {
            background-image: linear-gradient(-30deg, $main-red-rgb-015, transparent 50%);
        }
        &.red:nth-child(2n)::before {
            background-image: linear-gradient(30deg, $main-red-rgb-015, transparent 50%);
        }
        .postcard__tagbox .yellow.play:hover {
            background: $main-yellow;
            color: black;
        }
        &.yellow .postcard__title:hover {
            color: $main-yellow;
        }
        &.yellow .postcard__bar {
            background-color: $main-yellow;
        }
        &.yellow::before {
            background-image: linear-gradient(
                -30deg,
                $main-yellow-rgb-015,
                transparent 50%
            );
        }
        &.yellow:nth-child(2n)::before {
            background-image: linear-gradient(
                30deg,
                $main-yellow-rgb-015,
                transparent 50%
            );
        }
    }
    @media screen and (min-width: 769px) {
        >article.third-bot-article.postcard{
            flex-wrap: inherit;
            .postcard__img {
                max-width: 300px;
                max-height: 100%;
                transition: transform 0.3s ease;
            }
            .postcard__text {
                padding: 3rem;
                width: 100%;
                .postcard__title {
                    font-size: 2rem;
                }
                .postcard__tagbox {
                    justify-content: start;
                }
                &:before {
                    content: "";
                    position: absolute;
                    display: block;
                    background: #18151f;
                    top: -20%;
                    height: 130%;
                    width: 55px;
                }
            }
            &:hover {
                .postcard__img {
                    transform: scale(1.1);
                }
            }
            &:nth-child(2n+1) {
                flex-direction: row;
            }
            &:nth-child(2n+0) {
                flex-direction: row-reverse;
            }
            &:nth-child(2n+1) .postcard__text::before {
                left: -12px !important;
                transform: rotate(4deg);
            }
            &:nth-child(2n+0) .postcard__text::before {
                right: -12px !important;
                transform: rotate(-4deg);
            }
            &.green::before {
                background-image: linear-gradient(
                    -80deg,
                    $main-green-rgb-015,
                    transparent 50%
                );
            }
            &.green:nth-child(2n)::before {
                background-image: linear-gradient(
                    80deg,
                    $main-green-rgb-015,
                    transparent 50%
                );
            }
            &.blue::before {
                background-image: linear-gradient(
                    -80deg,
                    $main-blue-rgb-015,
                    transparent 50%
                );
            }
            &.blue:nth-child(2n)::before {
                background-image: linear-gradient(80deg, $main-blue-rgb-015, transparent 50%);
            }
            &.red::before {
                background-image: linear-gradient(-80deg, $main-red-rgb-015, transparent 50%);
            }
            &.red:nth-child(2n)::before {
                background-image: linear-gradient(80deg, $main-red-rgb-015, transparent 50%);
            }
            &.yellow::before {
                background-image: linear-gradient(
                    -80deg,
                    $main-yellow-rgb-015,
                    transparent 50%
                );
            }
            &.yellow:nth-child(2n)::before {
                background-image: linear-gradient(
                    80deg,
                    $main-yellow-rgb-015,
                    transparent 50%
                );
            }
        }
    }
    @media screen and (min-width: 1024px){
        >article.third-bot-article.postcard{
            .dark {
                .postcard__text:before {
                    background: #18151f;
                }
            }
            &.light {
                .postcard__text:before {
                    background: #e1e5ea;
                }
            }
            .postcard__text {
                padding: 2rem 3.5rem;
                &:before {
                    content: "";
                    position: absolute;
                    display: block;
                    top: -20%;
                    height: 130%;
                    width: 55px;
                }
            }
        }
    }
}
            