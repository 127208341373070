.auth-form{
    width: 100%;
    min-width: 320px;
    max-width: 400px;
    padding: 15px;
    margin: auto;
    h3{
        text-align: center;
    }
    .checkbox {
        font-weight: 400;
    }
    form-floating:focus-within {
        z-index: 2;
    }
    form{
        input{
            margin-bottom: -1px;
        }
    }
    &.form-signin{
        form{
            input[type="email"] {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
            input[type="password"] {
                margin-bottom: 10px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            
        }
        }
    }
    &.form-signup{
        form{
            input#floatingFirstnameInput{
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
            input#floatingLastnameInput{
                border-radius: 0;
            }
            input#floatingEmailInput{
                border-radius: 0;
            }
            input#floatingPasswordInput {
                margin-bottom: 10px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }
}
