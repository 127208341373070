.image-hover {
    overflow: hidden;
    >img{
        width: 100%;
        transition: transform 0.4s;
    }
    .img-fluid {
        max-width: 100%;
        height: auto;
    }
    .thumb-title {
        transition: transform 0.4s;
        padding: 14px 24px;
        background: #000;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #ffffff;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    &:hover {
        img {
            transform: scale(1.25);
        }
        .thumb-title {
            background: #0a62fb;
        }
    }
}
.font-weight-600 {
    font-weight: 600;
}