.weather-card {
    height: auto;
    width: 100%;
    background: #fff;
    box-shadow: 0 1px 38px rgba(0, 0, 0, 0.15), 0 5px 12px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    .top {
        position: relative;
        height: auto;
        width: 100%;
        overflow: hidden;
        background-size: cover;
        background-position: center center;
        text-align: center;
        .wrapper {
            padding: 30px;
            position: relative;
            z-index: 1;
            >.fetching-div{
                position:absolute;
                color:#FFFFFF;
            }
            .loading {
                margin-top: 20px;
                font-size: 20px;
                font-weight: 400;
                color: #fff;
            }
            .heading {
                margin-top: 20px;
                font-size: 35px;
                font-weight: 400;
                color: #fff;
            }
            .options-nav {
                display:flex;
                // height: 20px; 
                .btn-reload{
                    margin-left: auto;
                    margin-right: 0!important;
                    svg.reload_icon{
                        width: 20px;
                        height: 20px;
                        fill:#FFFFFF;
                    }
                }
            }
            .location {
                margin-top: 20px;
                font-size: 21px;
                font-weight: 400;
                color: #fff;
            }
            .temp-type {
                color: #fff;
                font-size: 85px;
            }
            .temp {
                margin-top: 20px;
                a {
                    text-decoration: none;
                    color: #fff;
                }
                .temp-value {
                    display: inline-block;
                    font-size: 85px;
                    font-weight: 600;
                    color: #fff;
                }
                .deg {
                    display: inline-block;
                    font-size: 35px;
                    font-weight: 600;
                    color: #fff;
                    vertical-align: top;
                    margin-top: 10px;
                }
            }
            .poweredByLink{
                position: absolute;
                font-size:12px;
                color: #999999;
                right:10px;
                >a{
                    color:#BBB
                }
            }
        }
        &:after {
            content: "";
            height: 100%;
            width: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
        }
    }
    .bottom {
        padding: 0 30px;
        background: #fff;
        .wrapper{
            .forecast {
                overflow: hidden;
                margin: 0;
                font-size: 0;
                padding: 0;
                padding-top: 20px;
                
                a {
                    text-decoration: none;
                    color: #000;
                }
                .go-up {
                    text-align: center;
                    display: block;
                    font-size: 25px;
                    margin-bottom: 10px;
                }
                li {
                    display: flex;
                    align-items: flex-start;
                    font-size: 20px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.8);
                    line-height: 1em;
                    margin-bottom: 30px;
                    // .date {
                        
                    // }
                    .condition {
                        margin-left:auto;
                        .temp {
                            .deg {
                                display: inline-block;
                                font-size: 10px;
                                font-weight: 600;
                                margin-left: 3px;
                                vertical-align: top;
                                margin-top: -5px;
                            }
                            .temp-type {
                                font-size: 20px;
                            }
                        }
                    }
                    // &.active {
                    //     color: rgba(0, 0, 0, 0.8);
                    // }
                }
            }
        }
    }
    .top {
        background-image: url("https://s-media-cache-ak0.pinimg.com/564x/cf/1e/c4/cf1ec4b0c96e59657a46867a91bb0d1e.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    &.rain{
        .top {
            background-image: url("http://img.freepik.com/free-vector/girl-with-umbrella_1325-5.jpg?size=338&ext=jpg") ;
        }
    }
    &.sunny{
        .top {
            background-image: url("https://cdn.userv.info/assets/images/news.userv.info/weather/sunny.png") ;
        }
    }
    &.partly-cloudy{
        .top {
            background-image: url("https://cdn.userv.info/assets/images/news.userv.info/weather/partly_cloudy.png") ;
        }
    }
    &.fetching{
        .top {
            background-image: url("https://cdn.userv.info/assets/images/news.userv.info/weather/placeholder.webp") ;
        }
    }
}
